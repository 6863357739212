exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cataloage-noi-js": () => import("./../../../src/pages/cataloage-noi.js" /* webpackChunkName: "component---src-pages-cataloage-noi-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magazine-carrefour-index-js": () => import("./../../../src/pages/magazine/carrefour/index.js" /* webpackChunkName: "component---src-pages-magazine-carrefour-index-js" */),
  "component---src-pages-magazine-index-js": () => import("./../../../src/pages/magazine/index.js" /* webpackChunkName: "component---src-pages-magazine-index-js" */),
  "component---src-pages-magazine-kaufland-index-js": () => import("./../../../src/pages/magazine/kaufland/index.js" /* webpackChunkName: "component---src-pages-magazine-kaufland-index-js" */),
  "component---src-pages-magazine-lidl-cataloage-catalog-2024-04-05-index-js": () => import("./../../../src/pages/magazine/lidl/cataloage/catalog-2024-04-05/index.js" /* webpackChunkName: "component---src-pages-magazine-lidl-cataloage-catalog-2024-04-05-index-js" */),
  "component---src-pages-magazine-lidl-index-js": () => import("./../../../src/pages/magazine/lidl/index.js" /* webpackChunkName: "component---src-pages-magazine-lidl-index-js" */),
  "component---src-pages-magazine-penny-market-index-js": () => import("./../../../src/pages/magazine/penny-market/index.js" /* webpackChunkName: "component---src-pages-magazine-penny-market-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-catalog-template-js": () => import("./../../../src/templates/catalogTemplate.js" /* webpackChunkName: "component---src-templates-catalog-template-js" */)
}

